import React, { useState, useEffect, useRef, useCallback } from "react";
import Cookies from 'universal-cookie';

import VOCClient from '../VOCClient';

const LoginContext = React.createContext([{}, () => {}]);

const LoginProvider = (props) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);

  const isRefreshingToken = useRef();

  async function runProtected(cb, ...args) {
    if (isRefreshingToken.current) {
      await new Promise((resolve) => {
        setInterval(() => {
          if (!isRefreshingToken.current) {
            resolve();
          }
        }, 100);
      });

      return cb(...args);
    }

    // refresh token if expired, then run callback
    isRefreshingToken.current = true;
    if (state.exp < Date.now() / 1000) {
      const token = window.localStorage.getItem('voc_token');
      await refresh(token);
    }
    isRefreshingToken.current = false;

    return cb(...args);
  }

  const logout = useCallback(() => {
    // clear cookies and local data
    const cookies = new Cookies();
    Object.keys(cookies.getAll()).forEach((cookie) => {
      cookies.remove(cookie, { path: '/' });
    });

    window.localStorage.removeItem('loggedInUserId');
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('voc_token');

    setState({});
  }, []);

  const saveUserData = useCallback((body) => {
    const { userData, refreshToken } = body;
    window.localStorage.setItem("userData", JSON.stringify(userData));
    if (refreshToken) {
      window.localStorage.setItem("voc_token", refreshToken);
    }
    setState((state) => ({ ...userData }));
  }, []);

  const refresh = useCallback(async (token) => {
    try {
      const res = await VOCClient.refresh(token);
      saveUserData(res.body);
    } catch (err) {
      // invalid refresh
      if (err.status === 401) {
        logout();
      } else {
        console.error(err);
      }
    }
  }, [logout, saveUserData]);

  useEffect(() => {
    const hasOldAuth = window.localStorage.getItem("loggedInUserId");
    if (hasOldAuth) {
      logout();
    }

    let data = window.localStorage.getItem("userData");
    if (!data) {
      setState({});
      setLoading(false);
      return;
    }

    data = JSON.parse(data);
    setState(data);
    setLoading(false);
  }, [logout]);

  if (loading) {
    return null;
  }

  return (
    <LoginContext.Provider value={{
      state,
      setState,
      loading,
      logout,
      saveUserData,
      runProtected
    }}>
      {props.children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
