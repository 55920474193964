import { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';

import { LoginContext } from '../context/login-context';

import useProtectedQuery from '../hooks/use-protected-query';
import useProtectedMutation from '../hooks/use-protected-mutation';

import VOCClient from "../VOCClient";

export default function useFarmField(props) {
  const { state: userData } = useContext(LoginContext);

  const [selectedCustomerId, setSelectedCustomerId] = useState(userData.customer_id);
  const [selectedFarmId, setSelectedFarmId] = useState(null);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [startRange, setStartRange] = useState(null);
  const [endRange, setEndRange] = useState("");

  const {
    data: images,
    isLoading: imagesLoading,
    error: imagesError,
    mutate: refetchImages
  } = useProtectedMutation({
    mutationFn: () => {
      let url = `/api/images/fields/${selectedFieldId}`;

      if (startRange || endRange) {
        const query = [];
        if (startRange) {
          query.push(`start=${startRange}`)
        }

        if (endRange) {
          query.push(`end=${endRange}`)
        }

        url += `?${query.join('&')}`;
      }

      return VOCClient.standardFetch(url).then(res => res.body.images);
    }
  });

  const {
    data: customers,
    isLoading: customersLoading,
    error: customersError
  } = useProtectedQuery({
    queryKey: ['get_customers'],
    queryFn: () => VOCClient.standardFetch('/api/admin/customers').then(res => {
      return res.body.customers.sort((a, b) => a.name < b.name ? -1 : 1);
    }),
    enabled: userData.admin
  });

  const {
    data: customer,
    isLoading: customerLoading,
    error: customerError,
    refetch: refetchCustomer
  } = useProtectedQuery({
    queryKey: ['get_customer', selectedCustomerId],
    queryFn: () => {
      let url = '/api/customer';
      if (userData.admin && userData.customer_id !== selectedCustomerId) {
        url += `?id=${selectedCustomerId}`;
      }

      return VOCClient.standardFetch(url).then(res => {
        const farms = res.body.customer.farms;

        farms.forEach((farm) => {
          farm.fields.sort((a, b) => a.name < b.name ? -1 : 1);

          farm.fields.forEach((field) => {
            field.cameras.sort((a, b) => a.custom_order - b.custom_order);
          });
        });

        farms.sort((a, b) => a.name < b.name ? -1 : 1);

        return res.body.customer;
      });
    },
    enabled: !userData.admin || !!(userData.admin && selectedCustomerId)
  });

  const selectedFarm = customer?.farms.find((f) => f.id === selectedFarmId);
  const selectedField = selectedFarm?.fields.find(
    (f) => f.id === selectedFieldId
  );

  const updateRangeStart = useCallback((field) => {
    const latestImageDate = (field?.cameras || [])
      .map(c => c.last_image?.created_at)
      .sort((a, b) => a.created_at < b.created_at ? 1 : -1)[0] || null;

    const sevenDaysAgo = new Date(
      new Date(latestImageDate).getTime() - (1000 * 60 * 60 * 24 * 7)
    ).toISOString();

    setStartRange(sevenDaysAgo);
  }, [])

  useEffect(() => {
    setSelectedFarmId(customer?.farms[0]?.id);
  }, [customer]);

  useEffect(() => {
    const firstField = selectedFarm?.fields[0] || null;
    updateRangeStart(firstField);
    setSelectedFieldId(firstField?.id || null);
  }, [selectedFarm, updateRangeStart]);

  useEffect(() => {
    if (selectedFieldId) {
      refetchImages();
    }
  }, [selectedFieldId, refetchImages]);

  function updateSelectedFieldId(fieldId) {
    const field = (selectedFarm?.fields || []).find(f => f?.id === fieldId);
    updateRangeStart(field);
    setSelectedFieldId(fieldId);
  }

  const scrubberMarkers = useMemo(() => {
    const timeSet = images?.reduce((set, image) => {
      const time = moment(image.created_at).startOf("minute");
      set.add(time.format("MM/DD/YYYY HH:mm"));

      return set;
    }, new Set()) || new Set();

    const times = Array.from(timeSet).sort((a, b) => (a < b ? -1 : 1));

    const marks = times.map((time, i) => {
      const timeNum = moment(time, "MM/DD/YYYY HH:mm").format("x");

      return {
        value: Number(timeNum),
        show: i === 0 || i === times.length - 1,
        label: time,
      };
    });

    return marks;
  }, [images]);

  return {
    selectedCustomerId,
    setSelectedCustomerId,

    selectedFarm,
    setSelectedFarmId,

    selectedField,
    setSelectedFieldId: updateSelectedFieldId,

    // customers
    customers: customers || [],
    customersLoading,
    customersError,

    //customer
    customer: customer || null,
    customerLoading,
    customerError,
    refetchCustomer,

    // images
    images,
    imagesLoading,
    imagesError,
    refetchImages,

    scrubberMarkers,

    startRange,
    setStartRange,
    endRange,
    setEndRange,

    isLoading: imagesLoading || customersLoading || customerLoading
  }
}
